<template>
  <div class="expanBox">
    <pcHeader />
    <div class="expanbanner">
      <img src="../assets/expanbg.jpg" />
      <div class="banner-title">{{ $t("lang.expansion.bannerTitle") }}</div>
    </div>
    <div class="expanAdvantage">
      <div class="titles">{{ $t("lang.expansion.titlesOne") }}</div>
      <div class="partbox">
        <div class="part">
          <img src="../assets/new1.png" />
          <div class="partTitle">{{ $t("lang.expansion.itemOne.title") }}</div>
          <span>{{ $t("lang.expansion.itemOne.desc") }}</span>
        </div>
        <div class="part">
          <img src="../assets/new2.png" />
          <div class="partTitle">{{ $t("lang.expansion.itemTwo.title") }}</div>
          <span>{{ $t("lang.expansion.itemTwo.desc") }}</span>
        </div>
      </div>
      <div class="partbox" style="margin-top: 100px">
        <div class="part">
          <img src="../assets/new3.png" />
          <div class="partTitle">
            {{ $t("lang.expansion.itemThree.title") }}
          </div>
          <span>{{ $t("lang.expansion.itemThree.desc") }}</span>
        </div>
        <div class="part">
          <img src="../assets/new4.png" />
          <div class="partTitle">{{ $t("lang.expansion.itemFour.title") }}</div>
          <span>{{ $t("lang.expansion.itemFour.desc") }}</span>
        </div>
      </div>
    </div>
    <div class="expanFooter">
      <div class="expancontainer">
        <div class="titleTop">{{ $t("lang.expansion.titleTop") }}</div>
        <div class="expandetail">
          <div class="detailPart">
            <div class="imgss">
              <img src="../assets/newbg.jpg" />
            </div>
            <div>
              <div class="detailname">
                {{ $t("lang.expansion.cloudItemO.title") }}
              </div>
              <div class="detaillist">
                <span>{{ $t("lang.expansion.cloudItemO.descOne") }}</span>
                <span>{{ $t("lang.expansion.cloudItemO.descTwo") }}</span>
              </div>
            </div>
          </div>
          <div class="detailPart">
            <div class="imgss">
              <img src="../assets/newbg2.jpg" />
            </div>
            <div>
              <div class="detailname">
                {{ $t("lang.expansion.cloudItemT.title") }}
              </div>
              <div class="detaillist">
                <span>{{ $t("lang.expansion.cloudItemT.descOne") }}</span>
                <span>{{ $t("lang.expansion.cloudItemT.descTwo") }}</span>
                <span>{{ $t("lang.expansion.cloudItemT.descThree") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pcFooter />
  </div>
</template>
<script>
import pcHeader from "@/components/pcHeader";
import pcFooter from "@/components/pcFooter";
export default {
  components: {
    pcHeader,
    pcFooter,
  },
};
</script>
<style lang="scss">
.expanBox {
  .expanbanner {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .banner-title {
      position: absolute;
      font-size: 55px;
      color: #fff;
      font-weight: bold;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .expanAdvantage {
    width: 1200px;
    margin: 0 auto;
    margin-top: 120px;
    .titles {
      font-size: 26px;
      font-weight: bold;
      margin-bottom: 100px;
    }
  }
  .partbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .part {
      width: calc(100% / 2);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .partTitle {
        font-size: 20px;
        font-weight: bold;
        margin: 30px 0;
        width: 400px;
        text-align: center;
      }
      span {
        width: 304px;
        font-size: 16px;
        color: #6d7276;
      }
    }
  }
  .expanFooter {
    width: 100%;
    background: url("../assets/exbg.jpg");
    background-size: contain;
    padding-bottom: 100px;
    margin: 100px 0 0;
    .expancontainer {
      width: 1200px;
      margin: 0 auto;
      color: #ffffff;
      .titleTop {
        font-size: 20px;
        padding-top: 60px;
      }
      .expandetail {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        .detailPart {
          display: flex;
          flex-direction: row;
          width: calc(100% / 2);
          .imgss {
            margin-right: 30px;
            img {
              width: 40px;
              height: 40px;
            }
          }
          .detailname {
            font-size: 20px;
            margin-bottom: 20px;
          }
          span {
            display: block;
            font-size: 16px;
            line-height: 30px;
          }
        }
      }
    }
  }
}
</style>
